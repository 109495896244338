import React from 'react'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import { GetServerSideProps } from 'next'
import { HomeContainer } from '../styles/pages/home'
import { getPublishedPosts } from '../services/posts'
import { Post } from '../types/post'

const PostList = dynamic(() => import('../components/postList'))

type HomeProps = {
  posts: Post[]
}

const Home: React.FC<HomeProps> = ({ posts }) => {
  return (
    <HomeContainer>
      <Head>
        <title>Ricardo Borges</title>
      </Head>
      <PostList posts={posts} />
    </HomeContainer>
  )
}

export const getServerSideProps: GetServerSideProps = async () => {
  try {
    const posts = await getPublishedPosts()
    return {
      props: { posts }
    }
  } catch (err) {
    console.error(err)
    return {
      props: { posts: [] }
    }
  }
}

export default Home
